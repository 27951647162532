<template>
  <div>
    FREE PALESTINE
  </div>
</template>

<script>
export default {
  name: 'israel'
}
</script>

<style scoped lang="scss">
@import "../assets/settings.scss";


</style>
